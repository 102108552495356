import React from 'react';
import {
    Modal,
    Button,
    Form,
    DatePicker,
    Select,
} from 'antd';
import PropTypes from 'prop-types';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ReportCreator = ({ visible, onClose, onSubmit }) => {
    const [form] = Form.useForm();

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            const [startDate, endDate] = values.dateRange;

            const formattedData = {
                ...values,
                start_date: startDate.format('YYYY-MM-DD'),
                end_date: endDate.format('YYYY-MM-DD'),
            };
            onSubmit(formattedData);
            form.resetFields();
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    return (
        <Modal
            title="Create New Report"
            open={visible}
            onCancel={onClose}
            width={800}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Create Report
                </Button>,
            ]}>
            <Form
                form={form}
                layout="vertical">
                <Form.Item
                    name="dateRange"
                    label="Report Period"
                    rules={[{ required: true, message: 'Please select report period' }]}>
                    <RangePicker style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                    name="overall_rag_rating"
                    label="Overall RAG Rating"
                    rules={[{ required: true, message: 'Please select RAG rating' }]}>
                    <Select>
                        <Option value="RED">Red</Option>
                        <Option value="AMBER">Amber</Option>
                        <Option value="GREEN">Green</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

ReportCreator.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};

ReportCreator.defaultProps = {
    visible: false,
    onClose: () => {},
    onSubmit: () => {},
};

export default ReportCreator;
