import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Space, Tooltip, Modal, Checkbox, Row, Col } from 'antd';
import { useReactToPrint } from 'react-to-print';
import EditableTable from '../EditableTable';
import getConfig, { TYPES } from './config';
import PrintBanner from '../PrintBanner';

const CheckboxGroup = Checkbox.Group;

const CoreTable = ({ type, dataSource, isFetching, printable, tableTitle, printStyles, projectName, clientName, selected, tableLabel, noFilter, totalShown, filterInfo, handleFilterChange, sorterInfo, handleSorterChange, filterValue, rowClassName, paginationObject }) => {
    const [isPrinting, setIsPrinting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const tableConfig = getConfig(type, dataSource, filterInfo, sorterInfo, filterValue);
    const defaultColumns = tableConfig?.columns.map((column, index) => ({
        label: column.title,
        value: column.key,
        disabled: index < 2,
    }));
    const defaultColumnValues = defaultColumns.map((column) => column.value);

    const [checkedColumns, setCheckedColumns] = useState(defaultColumnValues);
    const [columns, setColumns] = useState(tableConfig?.columns);
    const [summary, setSummary] = useState(tableConfig?.summaryConfig);
    const pageRef = useRef();

    useEffect(() => {
        setColumns(tableConfig?.columns);
    }, [filterInfo, sorterInfo, dataSource]);

    // Get the print styles as a string.
    const handlePrint = useReactToPrint({
        content: () => pageRef?.current,
        onAfterPrint: () => {
            setIsPrinting(false);
            setColumns(tableConfig?.columns);
            setSummary(tableConfig?.summaryConfig);
        },
        pageStyle: printStyles,
        documentTitle: `${projectName} - ${tableConfig?.typeName} - ${moment().format('DD-MM-YYYY')}`,
    });

    const handleExport = () => {
        const newColumn = tableConfig?.columns.filter((column) => checkedColumns.includes(column.key));
        const newSummary = {
            ...tableConfig?.summaryConfig,
            columns: tableConfig?.summaryConfig.columns.filter((column) => checkedColumns.includes(column.key)),
        };
        setColumns(newColumn);
        setSummary(newSummary);
        setIsModalOpen(false);
        setIsPrinting(true);
        setTimeout(handlePrint, 500);
    };

    const handleOnChange = (pagination, filters, sorters) => {
        handleFilterChange(filters);
        handleSorterChange(sorters);
    };

    const handleReset = () => {
        handleFilterChange(null);
        handleSorterChange(null);
    };

    const getSelectedAsText = () => {
        if (type === TYPES.BILLING_PERIOD_BREAKDOWN || type === TYPES.WATERFALL_ISSUE_BREAKDOWN) {
            return selected;
        }

        return selected;
    };

    const getSelectedForPrinting = () => {
        if (type === TYPES.BILLING_PERIOD_BREAKDOWN || type === TYPES.WATERFALL_ISSUE_BREAKDOWN) {
            return `Billing Period: ${selected}`;
        }

        return `Sprint: ${selected}`;
    };

    const onChange = (column) => {
        setCheckedColumns(column);
    };

    const renderTable = useMemo(() => (
        <EditableTable
            title={tableTitle}
            columns={columns}
            loading={isFetching}
            dataSource={dataSource}
            summary={summary}
            expanded={isPrinting}
            resettable
            rowClassNameFun={rowClassName}
            onChangeCallback={handleOnChange}
            totalShown={totalShown}
            pagination={paginationObject} />
    ), [dataSource, isFetching, isPrinting, filterInfo, sorterInfo, selected, columns, summary]);

    return (
        <div>
            { selected && isPrinting && (<h3 style={{ marginBottom: 20 }}>{ getSelectedAsText() }</h3>) }
            <Space>
                { tableLabel && (<h3>{ tableLabel }</h3>) }
                { !noFilter && <Button className="primary-button" type="primary" onClick={() => handleReset()}>Reset Filters</Button>}
                { printable && (
                    <Tooltip title="Enable 'Background graphics' in the pop-up!">
                        <Button className="default-button" onClick={() => setIsModalOpen(true)} disabled={isFetching || isPrinting}>Export to PDF</Button>
                    </Tooltip>
                )}
            </Space>
            <div ref={pageRef}>
                <div className="printable">
                    <PrintBanner />
                </div>
                <div className="printable-content">
                    <div className="printable">
                        <div className="sm-seperator" />
                        <h2>Client Name: { clientName }</h2>
                        <h2>Project Name: { projectName }</h2>
                        { selected && (<h2>{ getSelectedForPrinting() }</h2>) }
                        <div className="xsm-seperator" />
                    </div>
                    <div className="sm-seperator" />
                    { renderTable }
                </div>
            </div>
            <Modal
                title="Select Columns to export"
                open={isModalOpen}
                width={700}
                onOk={async () => {
                    try {
                        handleExport();
                    } catch (e) {
                        // Do nothing, antd handles this itself.
                    }
                }}
                onCancel={() => setIsModalOpen(false)}
                okButtonProps={{ className: 'primary-button' }}
                cancelButtonProps={{ className: 'default-button' }}
                okText="Export">
                <CheckboxGroup defaultValue={checkedColumns} onChange={onChange}>
                    <Row gutter={[16, 16]}>
                        {defaultColumns.map((column) => (
                            <Col key={column.value} span={12}>
                                <Checkbox key={column.value} value={column.value} style={{ width: '100%' }} disabled={column.disabled}>{column.label}</Checkbox>
                            </Col>
                        ))}
                    </Row>
                </CheckboxGroup>
            </Modal>
        </div>
    );
};

CoreTable.propTypes = {
    tableRef: PropTypes.object,
    type: PropTypes.string.isRequired,
    dataSource: PropTypes.array,
    isFetching: PropTypes.bool,
    printable: PropTypes.bool,
    printStyles: PropTypes.string,
    projectName: PropTypes.string,
    clientName: PropTypes.string,
    selected: PropTypes.string,
    tableLabel: PropTypes.string,
    noFilter: PropTypes.bool,
    totalShown: PropTypes.bool,
    filterInfo: PropTypes.object,
    handleFilterChange: PropTypes.func,
    sorterInfo: PropTypes.object,
    handleSorterChange: PropTypes.func,
    filterValue: PropTypes.object,
    rowClassName: PropTypes.func,
    paginationObject: PropTypes.object,
    tableTitle: PropTypes.func,
};

CoreTable.defaultProps = {
    tableRef: null,
    dataSource: [],
    isFetching: false,
    printable: false,
    printStyles: null,
    projectName: null,
    clientName: null,
    selected: null,
    tableLabel: null,
    noFilter: false,
    totalShown: true,
    filterInfo: null,
    handleFilterChange: () => {},
    sorterInfo: null,
    handleSorterChange: () => {},
    filterValue: null,
    rowClassName: null,
    paginationObject: null,
    tableTitle: () => {},
};

export default CoreTable;
