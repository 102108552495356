import React from 'react';
import { Card } from 'antd';
import './customerSatisfaction.scss';
import PropTypes from 'prop-types';
import moment from 'moment';

const CustomerSatisfaction = ({ data }) => {

    const formatDate = (date) => moment(date).format('DD/MM/YYYY');

    const isSelected = (range) => {
        const score = data?.customer_satisfaction_score;
        if (!score) return false;

        const [min, max] = range.split('-').map(Number);
        return score >= min && score <= max;
    };

    const renderScoreButton = (emoji, scoreRange) => (
        <div className="satisfaction-score-item">
            <div
                className={`satisfaction-score-button ${isSelected(scoreRange) ? 'selected' : ''}`}
                style={{
                    opacity: isSelected(scoreRange) ? 1 : 0.3,
                    padding: '10px 15px',
                    fontSize: '24px',
                    cursor: 'pointer',
                    border: 'none',
                    boxShadow: 'none',
                    transition: 'all 0.3s',
                }}>
                <h1 style={{ margin: 0 }}>{emoji}</h1>
            </div>
            <div className="satisfaction-score-label" style={{ marginTop: '8px', textAlign: 'center' }}>
                {scoreRange}
            </div>
        </div>
    );

    return (
        <Card className="customer-satisfaction-card">
            <h3>Customer Satisfaction Score</h3>
            <div className="satisfaction-scores">
                {renderScoreButton('😖', '1-2', 1)}
                {renderScoreButton('😟', '3-4', 3)}
                {renderScoreButton('😐', '5-6', 5)}
                {renderScoreButton('😁', '7-8', 7)}
                {renderScoreButton('🤩', '9-10', 9)}

            </div>
            <div className="satisfaction-description">
                <h4>Date</h4>
                <p>{data?.feedback_date ? formatDate(data?.feedback_date) : 'N/A'}</p>
                <h4>On a scale of 0 to 10, how likely are you to recommend Scorchsoft to a friend or colleague?</h4>
                <p>{data?.customer_satisfaction_score ? data?.customer_satisfaction_score : 'N/A'}</p>
                <h4>In your opinion, what can we do to improve this score? (Or maintain it)</h4>
                <p>{data?.customer_note ? data?.customer_note : 'N/A'}</p>
            </div>
        </Card>
    );
};

CustomerSatisfaction.propTypes = {
    data: PropTypes.object,
};

CustomerSatisfaction.defaultProps = {
    data: {},
};

export default CustomerSatisfaction;
