/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Button, Dropdown, message, Space, Spin } from 'antd';
import { filter, flatten, isNull, map, uniq } from 'underscore';
import { capitalize } from 'underscore.string';
import { DownOutlined } from '@ant-design/icons';
import Main from '../../components/layout/Main';

import './dashboard.scss';
import Card from '../../components/Card/Card';
import { getDashProjectsRequest, setDashboardUrl } from '../../core/dashboard/dashboardActions';
import { getAllUserRequest } from '../../core/user/userActions';
import { STATUS_ACTIVE, STATUS_INACTIVE, ROLE_DEVELOPER, ROLE_LEAD_DEVELOPER, ROLE_PROJECT_TEAM } from '../../core/utils/values';

const statusDropdownOptions = [
    {
        label: 'Active',
        key: STATUS_ACTIVE,
    },
    {
        label: 'Inactive',
        key: STATUS_INACTIVE,
    },
];

const typeDropdownOptions = [
    {
        label: 'Sprint',
        key: 'sprint',
    },
    {
        label: 'Waterfall',
        key: 'waterfall',
    },
    {
        label: 'Retainer',
        key: 'retainer',
    },
];
const activeStatus = 10;

const Dashboard = () => {
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const { projectData } = useSelector((state) => state?.dashboard) || {};
    const { userData, users } = useSelector((state) => state?.user || {});
    const [project, setProject] = useState([]);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const [pmFilter, setPmFilter] = useState(searchParams.get('pm'));
    const [ldFilter, setLdFilter] = useState(searchParams.get('ld'));
    const [devFilter, setDevFilter] = useState(searchParams.get('dev'));
    const [typeFilter, setTypeFilter] = useState(searchParams.get('type'));
    const [statusFilter, setStatusFilter] = useState(searchParams.get('status'));
    const currentYear = new Date().getFullYear();
    const [isLoggedUser, setIsLoggedUser] = useState(null);
    const pmDropdownData = useMemo(() => {
        const pms = map(project, p => {
            if (p?.project_manager?.status === activeStatus) {
                return { label: p?.project_manager?.name, key: p?.project_manager?.name };
            }
            return null;
        }).filter(Boolean);
        const unique = uniq(pms, false, p => p.label);
        return filter(unique, u => u.label && u.key);
    }, [project]);

    const ldDropdownData = useMemo(() => {
        const lds = map(project, p => {
            if (p?.lead_dev?.status === activeStatus) {
                return { label: p?.lead_dev?.name, key: p?.lead_dev?.name };
            }
            return null;
        }).filter(Boolean);
        const unique = uniq(lds, false, l => l.label);
        return filter(unique, u => u.label && u.key);
    }, [project]);

    const devDropdownData = useMemo(() => {
        // Create an array of developer objects from each project
        const devs = flatten(map(project, p => p?.developers?.filter(dev => dev.status === 10).map(dev => ({
            label: dev.name,
            key: dev.name,
        })) || []));

        const unique = uniq(devs, false, l => l.label);
        return filter(unique, u => u.label && u.key);
    }, [project]);

    const filterConfig = useMemo(() => {
        const config = [{
            label: 'Project Manager',
            key: 'pm',
            data: pmDropdownData,
            state: pmFilter,
            setState: (val) => setPmFilter(val),
        },
        {
            label: 'Lead Developer',
            key: 'ld',
            data: ldDropdownData,
            state: ldFilter,
            setState: (val) => setLdFilter(val),
        },
        {
            label: 'Developer',
            key: 'dev',
            data: devDropdownData,
            state: devFilter,
            setState: (val) => setDevFilter(val),
        },
        {
            label: 'Type',
            key: 'type',
            data: typeDropdownOptions,
            state: typeFilter,
            setState: (val) => setTypeFilter(val),
        },
        {
            label: 'Status',
            key: 'status',
            data: statusDropdownOptions,
            state: statusFilter,
            setState: (val) => setStatusFilter(val),
        }];

        return config;
    }, [pmDropdownData, ldDropdownData, pmFilter, ldFilter, devFilter, devDropdownData, typeFilter, statusFilter]);

    const addOrUpdateParam = (key, value) => {
        searchParams.set(key, value);
        navigate({ search: searchParams.toString() });
        dispatch(setDashboardUrl(searchParams.toString()));
    };

    const resetFilters = () => {
        setLdFilter(null);
        setPmFilter(null);
        setTypeFilter(null);
        setStatusFilter(STATUS_ACTIVE);
        setDevFilter(null);

        searchParams.delete('pm');
        searchParams.delete('ld');
        searchParams.delete('dev');
        searchParams.delete('type');
        searchParams.delete('status');

        navigate({ search: searchParams.toString() });
        dispatch(setDashboardUrl(searchParams.toString()));
    };

    const filteredProjects = useMemo(() => (
        filter(project, p => {
            const pmCondition = pmFilter ? (p?.project_manager?.name === pmFilter) : true;
            const ldCondition = ldFilter ? (p?.lead_dev?.name === ldFilter) : true;
            const devCondition = devFilter ? p?.developers?.some(dev => dev.name === devFilter) : true;
            const typeConditionCalc = () => {
                let condition = true;
                if (!isNull(typeFilter)) {
                    if ((p.agile && typeFilter === 'sprint' && p.type === 30) || (!p.agile && typeFilter === 'waterfall') || (p.agile && typeFilter === 'retainer' && p.type === 20)) {
                        condition = true;
                    } else {
                        condition = false;
                    }
                }

                return condition;
            };
            const typeCondition = typeConditionCalc();

            const statusConditionCalc = () => {
                let condition = true;
                if (!isNull(statusFilter)) {
                    if ((p.status === true && statusFilter === 'active') || (p.status === false && statusFilter === 'inactive')) {
                        condition = true;
                    } else {
                        condition = false;
                    }
                }

                return condition;
            };

            const statusCondition = statusConditionCalc();
            return (pmCondition && ldCondition && typeCondition && statusCondition && devCondition);
        })
    ), [project, pmFilter, ldFilter, devFilter, typeFilter, statusFilter]);

    useEffect(() => {
        dispatch(getDashProjectsRequest());
        dispatch(getAllUserRequest());
        setStatusFilter(STATUS_ACTIVE);
        setIsLoggedUser(1);
        searchParams.delete('dev');
        searchParams.delete('ld');
        searchParams.delete('pm');
        navigate({ search: searchParams.toString() });
    }, []);

    useEffect(() => {
        if (isLoggedUser && userData?.roles?.some(role => role.toLowerCase() === ROLE_DEVELOPER)) {
            const username = userData?.first_name;
            const selectedDev = devDropdownData?.find((userItem) => userItem?.label?.toLowerCase()?.includes(username?.toLowerCase()));
            if (selectedDev) {
                setDevFilter(selectedDev.key);
            }
            setIsLoggedUser(null);
        }
    }, [devDropdownData]);

    useEffect(() => {
        if (isLoggedUser && userData?.roles?.some(role => role.toLowerCase() === ROLE_LEAD_DEVELOPER)) {
            const username = userData?.first_name;
            const selectedDev = ldDropdownData?.find((userItem) => userItem?.label?.toLowerCase()?.includes(username?.toLowerCase()));
            if (selectedDev) {
                setLdFilter(selectedDev.key);
            }
            setIsLoggedUser(null);
        }
    }, [ldDropdownData]);

    useEffect(() => {
        if (isLoggedUser && userData?.roles?.some(role => role.toLowerCase() === ROLE_PROJECT_TEAM)) {
            const username = userData?.first_name;
            const selectedDev = pmDropdownData?.find((userItem) => userItem?.label?.toLowerCase()?.includes(username?.toLowerCase()));
            if (selectedDev) {
                setPmFilter(selectedDev.key);
            }
            setIsLoggedUser(null);
        }
    }, [pmDropdownData]);

    useEffect(() => {
        if (projectData) {
            setProject(projectData);
        }
    }, [projectData]);

    const renderProjectCard = (data) => (
        <Card projects={data} />
    );
    return (
        <Main
            className="login"
            title={null}
            headerTitle={null}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; Scorchsoft Ltd {currentYear} </div>}
            floatingFooter
            onLogout={null}>
            <div style={{ marginInline: '50px' }}>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <h1>Dashboard</h1>
                </div>
                <div style={{ flexDirection: 'row', marginBottom: 40 }}>
                    <Button style={{ marginRight: 10 }} type="primary" className="primary-button" onClick={() => resetFilters()}>
                        <Space>
                            Reset filters
                        </Space>
                    </Button>
                    { filterConfig ? (
                        filterConfig.map(f => {
                            if (f?.data?.length > 0) {
                                return (
                                    <Dropdown
                                        overlayClassName="dropdown"
                                        overlayStyle={{ width: 200, marginRight: 10, maxHeight: 300, overflowY: 'auto' }}
                                        menu={{
                                            items: f.data,
                                            onClick: ({ key }) => {
                                                f.setState(key);
                                                addOrUpdateParam(f.key, key);
                                            },
                                            selectable: true,
                                        }}>
                                        <Button style={{ width: 200, marginRight: 10, color: (f?.state) ? 'black' : '#a7a7a7' }}>
                                            <Space style={{ justifyContent: 'space-between', width: 170 }}>
                                                {f?.state ? capitalize(f?.state) : f.label}
                                                <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                );
                            }
                            return null;
                        })
                    ) : null }
                    <Button style={{ marginRight: 10 }} className="primary-button" type="primary">
                        <Link to="/report/create">
                            <Space>
                                Create Project
                            </Space>
                        </Link>
                    </Button>
                </div>
                <div style={{ marginBottom: '100px' }}>
                    {!projectData && (<Spin style={{ margin: 10 }} size="large" />)}
                    {projectData && renderProjectCard(filteredProjects)}
                </div>
            </div>
        </Main>
    );
};

export default Dashboard;

Dashboard.propTypes = {};
