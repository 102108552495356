import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Row, Space, Spin, Switch, Checkbox } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { filter, isEmpty, isNull, map, uniq } from 'underscore';
import { useLocation, useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { capitalize } from 'underscore.string';
import Main from '../../components/layout/Main';
import { getProjectsRequest, updateReportedStatusRequest } from '../../core/programmeOverview/programmeOverviewActions';
import { getDashProjectsRequest, setDashboardUrl } from '../../core/dashboard/dashboardActions';
import CoreTable from '../../components/CoreTable/CoreTable';
import './programmeOverview.css';
import { TYPES } from '../../components/CoreTable/config';
import EditableTable from '../../components/EditableTable';
import { colors, projectType, scoreStatus, STATUS_ACTIVE, STATUS_INACTIVE } from '../../core/utils/values';

const COL_WIDTH_DATE = 180;
const COL_WIDTH_TIME = 100;

const ProgrammeOverview = () => {
    const dispatch = useDispatch();
    const { projects, isFetching, isFetchingReportedStatus } = useSelector(state => state.programmeOverview);
    const { projectData } = useSelector((state) => state?.dashboard) || {};
    const [dataSource, setDataSource] = useState([]);
    const [project, setProject] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

    const handleUpdateReportedStatus = (id, reported) => {
        dispatch(updateReportedStatusRequest({ id, reported }));
    };
    const currentYear = new Date().getFullYear();
    const [projectFilter, setProjectFilter] = useState(searchParams.get('project'));
    const [complianceStatusFilter, setComplianceStatusFilter] = useState(searchParams.get('complianceStatus'));
    const [statusFilter, setStatusFilter] = useState(searchParams.get('status'));
    const [pmFilter, setPmFilter] = useState(searchParams.get('pm'));
    const [ldFilter, setLdFilter] = useState(searchParams.get('ld'));
    const [typeFilter, setTypeFilter] = useState(searchParams.get('type'));
    const activeStatus = 10;

    const pmDropdownData = useMemo(() => {
        const pms = map(project, p => {
            if (p?.project_manager?.status === activeStatus) {
                return { label: p?.project_manager?.name, key: p?.project_manager?.name };
            }
            return null;
        }).filter(Boolean);
        const unique = uniq(pms, false, p => p.label);
        return filter(unique, u => u.label && u.key);
    }, [project]);

    const ldDropdownData = useMemo(() => {
        const lds = map(project, p => {
            if (p?.lead_dev?.status === activeStatus) {
                return { label: p?.lead_dev?.name, key: p?.lead_dev?.name };
            }
            return null;
        }).filter(Boolean);
        const unique = uniq(lds, false, l => l.label);
        return filter(unique, u => u.label && u.key);
    }, [project]);
    const projectDropdownData = useMemo(() => {
        const projectsData = map(project, p => ({ label: `${p?.code} - ${p?.name}`, key: `${p?.code} - ${p?.name}`, value: p?.id })).filter(Boolean);
        return filter(projectsData, u => u.label && u.key);
    }, [project]);

    const typeDropdownOptions = Object.entries(projectType).map(([, name]) => ({
        label: name,
        key: name.toLowerCase(),
    }));
    const statusDropdownOptions = [
        {
            label: 'Active',
            key: STATUS_ACTIVE,
        },
        {
            label: 'Inactive',
            key: STATUS_INACTIVE,
        },
    ];
    const filterConfig = useMemo(() => {
        const config = [
            {
                label: 'Project Manager',
                key: 'pm',
                data: pmDropdownData,
                state: pmFilter,
                setState: (val) => setPmFilter(val),
            },
            {
                label: 'Lead Developer',
                key: 'ld',
                data: ldDropdownData,
                state: ldFilter,
                setState: (val) => setLdFilter(val),
            },
            {
                label: 'Project',
                key: 'project',
                data: projectDropdownData,
                state: projectFilter,
                setState: (val) => setProjectFilter(val),
            },
            {
                label: 'Status',
                key: 'status',
                data: statusDropdownOptions,
                state: statusFilter,
                setState: (val) => setStatusFilter(val),
            },
            {
                label: 'Type',
                key: 'type',
                data: typeDropdownOptions,
                state: typeFilter,
                setState: (val) => setTypeFilter(val),
            },
        ];

        return config;
    }, [projectFilter, complianceStatusFilter, statusFilter, pmFilter, ldFilter, typeFilter]);

    const addOrUpdateParam = (key, value) => {
        searchParams.set(key, value);
        navigate({ search: searchParams.toString() });
        dispatch(setDashboardUrl(searchParams.toString()));
    };

    const resetFilters = () => {
        setProjectFilter(null);
        setComplianceStatusFilter(null);
        setStatusFilter(STATUS_ACTIVE);
        setLdFilter(null);
        setPmFilter(null);
        setTypeFilter(null);

        searchParams.delete('pm');
        searchParams.delete('ld');
        searchParams.delete('type');
        searchParams.delete('project');
        searchParams.delete('complianceStatus');
        searchParams.delete('status');

        navigate({ search: searchParams.toString() });
        dispatch(setDashboardUrl(searchParams.toString()));
    };

    useEffect(() => {
        if (projectData) {
            setProject(projectData);
        }
    }, [projectData]);

    useEffect(() => {
        dispatch(getDashProjectsRequest());
        setStatusFilter(STATUS_ACTIVE);
        navigate({ search: searchParams.toString() });
    }, []);

    const filteredProjects = useMemo(() => (
        filter(project, p => {
            const projectCondition = projectFilter ? (`${p?.code} - ${p?.name}` === projectFilter) : true;
            const pmCondition = pmFilter ? (p?.project_manager?.name === pmFilter) : true;
            const ldCondition = ldFilter ? (p?.lead_dev?.name === ldFilter) : true;
            const complianceStatusConditionCalc = () => {
                let condition = true;
                if (!isNull(complianceStatusFilter)) {
                    if ((p?.health_status_score === colors.COLOR_GREEN && complianceStatusFilter === scoreStatus[colors.COLOR_GREEN])
                        || (p?.health_status_score === colors.COLOR_RED && complianceStatusFilter === scoreStatus[colors.COLOR_RED])
                        || (p?.health_status_score === colors.COLOR_AMBER && complianceStatusFilter === scoreStatus[colors.COLOR_AMBER])) {
                        condition = true;
                    } else {
                        condition = false;
                    }
                }

                return condition;
            };

            const complianceStatusCondition = complianceStatusConditionCalc();
            const statusConditionCalc = () => {
                let condition = true;
                if (!isNull(statusFilter)) {
                    if ((p.status === true && statusFilter === 'active') || (p.status === false && statusFilter === 'inactive')) {
                        condition = true;
                    } else {
                        condition = false;
                    }
                }

                return condition;
            };

            const statusCondition = statusConditionCalc();

            const typeConditionCalc = () => {
                let condition = true;
                if (!isNull(typeFilter)) {
                    if ((p.agile && typeFilter === 'sprint' && p.type === 30) || (!p.agile && typeFilter === 'waterfall') || (p.agile && typeFilter === 'retainer' && p.type === 20)) {
                        condition = true;
                    } else {
                        condition = false;
                    }
                }

                return condition;
            };
            const typeCondition = typeConditionCalc();
            return (pmCondition && ldCondition && projectCondition && statusCondition && complianceStatusCondition && typeCondition);
        })
    ), [project, projectFilter, complianceStatusFilter, statusFilter, pmFilter, ldFilter, typeFilter]);

    const columns = [
        {
            title: 'Project Code',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            fixed: 'left',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Project Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: 'Report',
            dataIndex: 'report',
            key: 'report',
            width: COL_WIDTH_TIME,
            render: (_, record) => (dataSource.length >= 1 ? (
                <Switch
                    loading={isFetchingReportedStatus}
                    size="small"
                    defaultChecked={record.reported}
                    onChange={() => handleUpdateReportedStatus(record.key, !record.reported)} />
            ) : null),
            align: 'center',
            filters: [
                {
                    text: 'Reported',
                    value: true,
                },
                {
                    text: 'Not Reported',
                    value: false,
                },
            ],
            onFilter: (filterValue, { reported }) => reported === filterValue,
        },
        {
            title: 'Actual Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            editable: true,
            width: COL_WIDTH_DATE,
        },
        {
            title: 'Planned Completion Date',
            dataIndex: 'plannedCompletionDate',
            key: 'plannedCompletionDate',
            editable: true,
            width: COL_WIDTH_DATE,
        },
        {
            title: 'Actual Completion Date',
            dataIndex: 'actualCompletionDate',
            key: 'actualCompletionDate',
            editable: true,
            width: COL_WIDTH_DATE,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            editable: true,
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Hourly rate',
            key: 'hourlyRate',
            dataIndex: 'hourlyRate',
            format: 'currency',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Planning budget',
            key: 'planningBudget',
            dataIndex: 'planningBudget',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Actual planning time',
            key: 'actualPlanningTime',
            dataIndex: 'actualPlanningTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Quoted graphics time',
            key: 'quotedGraphicsTime',
            dataIndex: 'quotedGraphicsTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Actual graphics time',
            key: 'actualGraphicsTime',
            dataIndex: 'actualGraphicsTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Productive Graphics Time',
            key: 'productiveGraphicsTime',
            dataIndex: 'productiveGraphicsTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Quoted dev time',
            key: 'quotedDevTime',
            dataIndex: 'quotedDevTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Actual Dev time',
            key: 'actualDevTime',
            dataIndex: 'actualDevTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Productive Dev Time',
            key: 'productiveDevTime',
            dataIndex: 'productiveDevTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Quoted PM time',
            key: 'quotedPMTime',
            dataIndex: 'quotedPMTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Actual PM time',
            key: 'actualPMTime',
            dataIndex: 'actualPMTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Productive PM Time',
            key: 'productivePMTime',
            dataIndex: 'productivePMTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'PM Testing Budget',
            key: 'pmTestingBudget',
            dataIndex: 'pmTestingBudget',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Actual PM Testing',
            key: 'actualPMTesting',
            dataIndex: 'actualPMTesting',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Productive PM Testing',
            key: 'productivePMTesting',
            dataIndex: 'productivePMTesting',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Dev Fixing Budget',
            key: 'devFixingBudget',
            dataIndex: 'devFixingBudget',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Actual Dev Fixing',
            key: 'actualDevFixing',
            dataIndex: 'actualDevFixing',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Productive Dev Fixing',
            key: 'productiveDevFixing',
            dataIndex: 'productiveDevFixing',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Σ Quoted hrs',
            key: 'sumQuotedHrs',
            dataIndex: 'sumQuotedHrs',
        },
        {
            title: 'Σ Actual hrs',
            key: 'sumActualHrs',
            dataIndex: 'sumActualHrs',
        },
        {
            title: 'Σ Quoted cost',
            key: 'sumQuotedCost',
            dataIndex: 'sumQuotedCost',
            format: 'currency',
        },
        {
            title: 'Σ Actual cost',
            key: 'sumActualCost',
            dataIndex: 'sumActualCost',
            format: 'currency',
        },
        {
            title: '%Δ hours',
            key: 'pcDeltaHours',
            dataIndex: 'pcDeltaHours',
            format: 'percent',
        },
    ];

    useEffect(() => {
        dispatch(getProjectsRequest({ type: 10, overview: true }));
    }, []);

    useEffect(() => {
        if (!isEmpty(projects)) {
            setDataSource(projects);
        }
    }, [projects]);

    const overviewTableHeader = () => (
        <Row style={{ justifyContent: 'space-between', marginInline: 15 }} align="middle">
            <h3>Programme Overview</h3>
        </Row>
    );

    const projectHealthStatusHeader = () => (
        <Row style={{ justifyContent: 'space-between', marginInline: 15 }} align="middle">
            <h3>Project Health Status</h3>
            <h5>Total Results: {filteredProjects?.length}</h5>
        </Row>
    );

    const renderOverviewTable = () => (
        <div>
            <EditableTable
                title={overviewTableHeader}
                columns={columns}
                dataSource={dataSource}
                setDataSource={(data) => { console.log(data); }}
                loading={isFetching}
                onLogout={null} />
        </div>
    );

    const renderProjectsHealthStatusTable = (proj) => (
        <div>
            <CoreTable
                tableTitle={projectHealthStatusHeader}
                type={TYPES.PROGRAMME_OVERVIEW_TABLE}
                dataSource={proj}
                isFetching={isFetching}
                noFilter
                printable />
            <div className="sm-seperator" />
        </div>

    );
    const onChange = (e) => {
        if (e.target.checked) {
            setComplianceStatusFilter(scoreStatus[colors.COLOR_RED]);
        } else {
            setComplianceStatusFilter(null);
        }
    };

    return (
        <Main
            title={null}
            floatingHeader={false}
            headerTitle={null}
            menuWidth={350}
            menuPlacement="right"
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; Scorchsoft Ltd {currentYear} </div>}
            floatingFooter>
            <div style={{ marginInline: '50px' }}>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <h1>Programme Overview</h1>
                </div>
                <div style={{ flexDirection: 'row', marginBottom: 40 }}>
                    <Button style={{ marginRight: 10 }} className="primary-button" type="primary" onClick={() => resetFilters()}>
                        <Space>
                            Reset filters
                        </Space>
                    </Button>
                    { filterConfig ? (
                        filterConfig.map(f => {
                            if (f?.data?.length > 0) {
                                return (
                                    <Dropdown
                                        overlayClassName="dropdown"
                                        overlayStyle={{ width: 200, marginRight: 10, maxHeight: 300, overflowY: 'auto' }}
                                        menu={{
                                            items: f.data,
                                            onClick: ({ key }) => {
                                                f.setState(key);
                                                addOrUpdateParam(f.key, key);
                                            },
                                            selectable: true,
                                        }}>
                                        <Button style={{ width: 200, marginRight: 10, color: (f?.state) ? 'black' : '#a7a7a7' }}>
                                            <Space style={{ justifyContent: 'space-between', width: 170 }}>
                                                {f?.state ? capitalize(f?.state) : f.label}
                                                <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                );
                            }
                            return null;
                        })
                    ) : null }
                    <Checkbox onChange={onChange} checked={complianceStatusFilter === scoreStatus[colors.COLOR_RED] || complianceStatusFilter === scoreStatus[colors.COLOR_AMBER]}>
                        Highlight critical
                    </Checkbox>
                </div>
                <div style={{ marginBottom: '20px' }}>
                    {!projectData && (<Spin style={{ margin: 10 }} size="large" />)}
                    {projectData && renderProjectsHealthStatusTable(filteredProjects)}
                </div>
                <div style={{ marginBottom: '100px' }}>
                    {renderOverviewTable()}
                </div>
            </div>
        </Main>
    );

};

export default ProgrammeOverview;
