import React from 'react';

import PropTypes from 'prop-types';

import './style.scss';
import moment from 'moment';

const TYPE_WATERFALL = 10;

const ReportSummaryBox = ({ projectType, snapshotData, dateRange }) => {
    const formatDate = (date) => {
        if (!date) return '-';
        return moment(date).format('DD/MM/YYYY');
    };

    return (
        <div className="summary-container">
            <div className="details-container">
                <div className="details-item">
                    <p className="label">Reporting Period</p>
                    <p>{dateRange}</p>
                </div>
                <div className="details-item">
                    <p className="label">Project Code</p>
                    <p>{snapshotData?.project_code || '-'}</p>
                </div>
            </div>
            <div className="details-container">
                <div className="details-item">
                    <p className="details-label">Client Name</p>
                    <p>{snapshotData?.client_name || '-'}</p>
                </div>
                <div className="details-item">
                    <p className="details-label">Project Name</p>
                    <p>{snapshotData?.project_name || '-'}</p>
                </div>

                <div className="details-item">
                    <p className="details-label">Project Owner</p>
                    <p>{snapshotData?.project_owner || '-'}</p>
                </div>
                <div className="details-item">
                    <p className="details-label">Secondary Stakeholders</p>
                    <p>{snapshotData?.secondary_stakeholders || '-'}</p>
                </div>
            </div>

            {projectType === TYPE_WATERFALL ? (
                <div className="details-container">
                    <div className="details-item">
                        <p className="details-label">Contract Signed Date</p>
                        <p>{snapshotData?.contract_date || '-'}</p>
                    </div>
                    <div className="details-item">
                        <p className="details-label">Project Start Date</p>
                        <p>{formatDate(snapshotData?.project_start_date) || '-'}</p>
                    </div>

                    <div className="details-item">
                        <p className="details-label">Feature Complete Date</p>
                        <p>{formatDate(snapshotData?.feature_complete_at) || '-'}</p>
                    </div>
                </div>
            ) : (
                <div className="details-container">
                    <div className="details-item">
                        <p className="details-label">Next Version</p>
                        <p>{snapshotData?.next_version || '-'}</p>
                    </div>
                    <div className="details-item">
                        <p className="details-label">Version Start Planning Date</p>
                        <p>{snapshotData?.version_start_planning_date || '-'}</p>
                    </div>
                    <div className="details-item">
                        <p className="details-label">Version Target Release Date</p>
                        <p>{snapshotData?.version_target_release_date || '-'}</p>
                    </div>
                </div>
            )}
            <div className="details-container" style={{ borderBottom: 'none' }}>
                <div className="details-item">
                    <p className="details-label">Project Coordinator</p>
                    <p>{snapshotData?.project_coordinator || '-'}</p>
                </div>
                <div className="details-item">
                    <p className="details-label">Scorchsoft Escalation Contact</p>
                    <p>{snapshotData?.escalation_contact || '-'}</p>
                </div>
            </div>
        </div>
    );
};

ReportSummaryBox.propTypes = {
    projectType: PropTypes.string,
    snapshotData: PropTypes.object,
    dateRange: PropTypes.string,
};

ReportSummaryBox.defaultProps = {
    projectType: 'waterfall',
    snapshotData: {},
    dateRange: '',
};

export default ReportSummaryBox;
