import { call, put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';
import {
    fetchPsrSnapshotSuccess,
    fetchPsrSnapshotFailure,

    fetchAllSnapshotsSuccess,
    fetchAllSnapshotsFailure,

    createPsrSnapshotSuccess,
    createPsrSnapshotFailure,

    updateSnapshotSuccess,
    updateSnapshotFailure,
} from './psrActions';

const {
    FETCH_PSR_SNAPSHOT_REQUEST,
    CREATE_PSR_SNAPSHOT_REQUEST,
    FETCH_ALL_SNAPSHOTS_REQUEST,
    UPDATE_SNAPSHOT_REQUEST,
} = require('./psrActions').constraints;

function* fetchPsrSnapshot({ payload }) {
    try {
        const { projectId, snapshotId } = payload;
        const url = `/project/${projectId}/psr/snapshots/${snapshotId}`;
        const data = yield call(fetchApiAuth, {
            method: 'GET',
            url,
        });
        yield put(fetchPsrSnapshotSuccess(data.data));
        message.success('PSR snapshot fetched');
    } catch (error) {
        yield put(fetchPsrSnapshotFailure(error.toString()));
    }
}

function* createPsrSnapshot({ payload }) {
    try {
        const { projectId, data } = payload;
        const url = `/project/${projectId}/psr/snapshots/create`;
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url,
            body: data,
        });

        yield put(fetchPsrSnapshotSuccess(response.data));
        yield put(createPsrSnapshotSuccess(response.data));
        message.success('PSR snapshot created');
    } catch (error) {
        yield put(createPsrSnapshotFailure(error.toString()));
    }
}

function* fetchAllSnapshots({ payload }) {
    try {
        const { projectId } = payload;
        const url = `/project/${projectId}/psr/snapshots`;
        const data = yield call(fetchApiAuth, {
            method: 'GET',
            url,
        });
        yield put(fetchAllSnapshotsSuccess(data.data));
        // call fetch all snapshots again
    } catch (error) {
        yield put(fetchAllSnapshotsFailure(error.toString()));
    }
}

function* updateSnapshot({ payload }) {
    try {
        const { projectId, data, snapshotId } = payload;
        const url = `/project/${projectId}/psr/snapshots/${snapshotId}/update`;
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url,
            body: data,
        });
        yield put(updateSnapshotSuccess(response.data));
        message.success('PSR snapshot updated');
    } catch (error) {
        yield put(updateSnapshotFailure(error.toString()));
    }
}

export default function* psrSagas() {
    yield* [
        takeEvery(FETCH_PSR_SNAPSHOT_REQUEST, fetchPsrSnapshot),
        takeEvery(CREATE_PSR_SNAPSHOT_REQUEST, createPsrSnapshot),
        takeEvery(FETCH_ALL_SNAPSHOTS_REQUEST, fetchAllSnapshots),
        takeEvery(UPDATE_SNAPSHOT_REQUEST, updateSnapshot),
    ];
}
