import React from 'react';

import PropTypes from 'prop-types';

import './style.scss';

const ReportCard = ({ title, value, color }) => (
    <div className="card">
        <div className="card-component">
            <div className="card-header">
                <p>{title}</p>
            </div>
            <div className="card-body" style={{ backgroundColor: color }}>
                <p>{value}</p>
            </div>
        </div>
    </div>
);

ReportCard.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    color: PropTypes.string,
};

ReportCard.defaultProps = {
    title: '',
    value: '',
    color: 'white',
};

export default ReportCard;
