import { all } from 'redux-saga/effects';
import projectAccuracySaga from './projectAccuracy/projectAccuracySagas';
import programmeOverviewSaga from './programmeOverview/programmeOverviewSagas';
import agileProjectSagas from './agileProject/agileProjectSagas';
import dashboardSagas from './dashboard/dashboardSagas';
import userSagas from './user/userSaga';
import psrSagas from './psr/psrSagas';
// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        programmeOverviewSaga(),
        projectAccuracySaga(),
        agileProjectSagas(),
        dashboardSagas(),
        userSagas(),
        psrSagas(),
    ]);
}
