import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constraints = keyMirror({
    GET_RETAINER_OVERVIEW_REQUEST: null,
    GET_RETAINER_OVERVIEW_SUCCESS: null,
    GET_RETAINER_OVERVIEW_FAILURE: null,

    RESET_RETAINER_OVERVIEW_REQUEST: null,
    RESET_RETAINER_OVERVIEW_SUCCESS: null,
    RESET_RETAINER_OVERVIEW_FAILURE: null,

    UPDATE_PROJECT_REQUEST: null,
    UPDATE_PROJECT_SUCCESS: null,
    UPDATE_PROJECT_FAILURE: null,

    GET_TICKETS_REQUEST: null,
    GET_TICKETS_SUCCESS: null,
    GET_TICKETS_FAILURE: null,

    GET_SPRINT_DATA_REQUEST: null,
    GET_SPRINT_DATA_SUCCESS: null,
    GET_SPRINT_DATA_FAILURE: null,

    GET_BILLING_PERIOD_DATA_REQUEST: null,
    GET_BILLING_PERIOD_DATA_SUCCESS: null,
    GET_BILLING_PERIOD_DATA_FAILURE: null,

    GET_SPRINT_PLAN_DATA_REQUEST: null,
    GET_SPRINT_PLAN_DATA_SUCCESS: null,
    GET_SPRINT_PLAN_DATA_FAILURE: null,

    UPDATE_DATA_SOURCE_REQUEST: null,
    UPDATE_DATA_SOURCE_SUCCESS: null,
    UPDATE_DATA_SOURCE_FAILURE: null,

    UPDATE_BILLING_PERIOD_DATA_REQUEST: null,
    UPDATE_BILLING_PERIOD_DATA_SUCCESS: null,
    UPDATE_BILLING_PERIOD_DATA_FAILURE: null,

    GET_PEV_INITIAL_VALUES_REQUEST: null,
    GET_PEV_INITIAL_VALUES_SUCCESS: null,
    GET_PEV_INITIAL_VALUES_FAILURE: null,

    GET_PEV_LATEST_SNAPSHOT_REQUEST: null,
    GET_PEV_LATEST_SNAPSHOT_SUCCESS: null,
    GET_PEV_LATEST_SNAPSHOT_FAILURE: null,

    GET_NEW_PEV_SNAPSHOT_REQUEST: null,
    GET_NEW_PEV_SNAPSHOT_SUCCESS: null,
    GET_NEW_PEV_SNAPSHOT_FAILURE: null,

    DELETE_BILLING_PERIOD_DATA_REQUEST: null,
    DELETE_BILLING_PERIOD_DATA_SUCCESS: null,
    DELETE_BILLING_PERIOD_DATA_FAILURE: null,

    GET_BUG_SUMMARY_REQUEST: null,
    GET_BUG_SUMMARY_SUCCESS: null,
    GET_BUG_SUMMARY_FAILURE: null,

    RESET_BUG_SUMMARY_REQUEST: null,
    RESET_BUG_SUMMARY_SUCCESS: null,
    RESET_BUG_SUMMARY_FAILURE: null,

    RESET_TICKETS_REQUEST: null,
    RESET_TICKETS_SUCCESS: null,
    RESET_TICKETS_FAILURE: null,

    GET_BUG_TICKET_BREAKDOWN_REQUEST: null,
    GET_BUG_TICKET_BREAKDOWN_SUCCESS: null,
    GET_BUG_TICKET_BREAKDOWN_FAILURE: null,

    GET_BUGS_REQUEST: null,
    GET_BUGS_SUCCESS: null,
    GET_BUGS_FAILURE: null,

    UPDATE_COMPONENTS_REQUEST: null,
    UPDATE_COMPONENTS_SUCCESS: null,
    UPDATE_COMPONENTS_FAILURE: null,

    UPDATE_BUDGETS_REQUEST: null,
    UPDATE_BUDGETS_SUCCESS: null,
    UPDATE_BUDGETS_FAILURE: null,

    UPDATE_PLANNING_BUDGETS_REQUEST: null,
    UPDATE_PLANNING_BUDGETS_SUCCESS: null,
    UPDATE_PLANNING_BUDGETS_FAILURE: null,

    EXPORT_SPEC_DOC_REQUEST: null,
    EXPORT_SPEC_DOC_SUCCESS: null,
    EXPORT_SPEC_DOC_FAILURE: null,

    GET_PULL_REQUESTS_REQUEST: null,
    GET_PULL_REQUESTS_SUCCESS: null,
    GET_PULL_REQUESTS_FAILURE: null,

    DELETE_PROJECT_REQUEST: null,
    DELETE_PROJECT_SUCCESS: null,
    DELETE_PROJECT_FAILURE: null,
});

export const {
    getRetainerOverviewRequest,
    getRetainerOverviewSuccess,
    getRetainerOverviewFailure,
} = createActions(
    constraints.GET_RETAINER_OVERVIEW_REQUEST,
    constraints.GET_RETAINER_OVERVIEW_SUCCESS,
    constraints.GET_RETAINER_OVERVIEW_FAILURE,
);

export const {
    resetRetainerOverviewRequest,
    resetRetainerOverviewSuccess,
    resetRetainerOverviewFailure,
} = createActions(
    constraints.RESET_RETAINER_OVERVIEW_REQUEST,
    constraints.RESET_RETAINER_OVERVIEW_SUCCESS,
    constraints.RESET_RETAINER_OVERVIEW_FAILURE,
);

export const {
    resetTicketsRequest,
    resetTicketsSuccess,
    resetTicketsFailure,
} = createActions(
    constraints.RESET_TICKETS_REQUEST,
    constraints.RESET_TICKETS_SUCCESS,
    constraints.RESET_TICKETS_FAILURE,
);

export const {
    updateProjectRequest,
    updateProjectSuccess,
    updateProjectFailure,
} = createActions(
    constraints.UPDATE_PROJECT_REQUEST,
    constraints.UPDATE_PROJECT_SUCCESS,
    constraints.UPDATE_PROJECT_FAILURE,
);

export const {
    getTicketsRequest,
    getTicketsSuccess,
    getTicketsFailure,
} = createActions(
    constraints.GET_TICKETS_REQUEST,
    constraints.GET_TICKETS_SUCCESS,
    constraints.GET_TICKETS_FAILURE,
);

export const {
    getSprintDataRequest,
    getSprintDataSuccess,
    getSprintDataFailure,
} = createActions(
    constraints.GET_SPRINT_DATA_REQUEST,
    constraints.GET_SPRINT_DATA_SUCCESS,
    constraints.GET_SPRINT_DATA_FAILURE,
);

export const {
    getBillingPeriodDataRequest,
    getBillingPeriodDataSuccess,
    getBillingPeriodDataFailure,
} = createActions(
    constraints.GET_BILLING_PERIOD_DATA_REQUEST,
    constraints.GET_BILLING_PERIOD_DATA_SUCCESS,
    constraints.GET_BILLING_PERIOD_DATA_FAILURE,
);

export const {
    getSprintPlanDataRequest,
    getSprintPlanDataSuccess,
    getSprintPlanDataFailure,
} = createActions(
    constraints.GET_SPRINT_PLAN_DATA_REQUEST,
    constraints.GET_SPRINT_PLAN_DATA_SUCCESS,
    constraints.GET_SPRINT_PLAN_DATA_FAILURE,
);

export const {
    updateDataSourceRequest,
    updateDataSourceSuccess,
    updateDataSourceFailure,
} = createActions(
    constraints.UPDATE_DATA_SOURCE_REQUEST,
    constraints.UPDATE_DATA_SOURCE_SUCCESS,
    constraints.UPDATE_DATA_SOURCE_FAILURE,
);

export const {
    updateBillingPeriodDataRequest,
    updateBillingPeriodDataSuccess,
    updateBillingPeriodDataFailure,
} = createActions(
    constraints.UPDATE_BILLING_PERIOD_DATA_REQUEST,
    constraints.UPDATE_BILLING_PERIOD_DATA_SUCCESS,
    constraints.UPDATE_BILLING_PERIOD_DATA_FAILURE,
);

export const {
    getPevInitialValuesRequest,
    getPevInitialValuesSuccess,
    getPevInitialValuesFailure,
} = createActions(
    constraints.GET_PEV_INITIAL_VALUES_REQUEST,
    constraints.GET_PEV_INITIAL_VALUES_SUCCESS,
    constraints.GET_PEV_INITIAL_VALUES_FAILURE,
);

export const {
    getPevLatestSnapshotRequest,
    getPevLatestSnapshotSuccess,
    getPevLatestSnapshotFailure,
} = createActions(
    constraints.GET_PEV_LATEST_SNAPSHOT_REQUEST,
    constraints.GET_PEV_LATEST_SNAPSHOT_SUCCESS,
    constraints.GET_PEV_LATEST_SNAPSHOT_FAILURE,
);

export const {
    getNewPevSnapshotRequest,
    getNewPevSnapshotSuccess,
    getNewPevSnapshotFailure,
} = createActions(
    constraints.GET_NEW_PEV_SNAPSHOT_REQUEST,
    constraints.GET_NEW_PEV_SNAPSHOT_SUCCESS,
    constraints.GET_NEW_PEV_SNAPSHOT_FAILURE,
);

export const {
    deleteBillingPeriodDataRequest,
    deleteBillingPeriodDataSuccess,
    deleteBillingPeriodDataFailure,
} = createActions(
    constraints.DELETE_BILLING_PERIOD_DATA_REQUEST,
    constraints.DELETE_BILLING_PERIOD_DATA_SUCCESS,
    constraints.DELETE_BILLING_PERIOD_DATA_FAILURE,
);

export const {
    getBugSummaryRequest,
    getBugSummarySuccess,
    getBugSummaryFailure,
} = createActions(
    constraints.GET_BUG_SUMMARY_REQUEST,
    constraints.GET_BUG_SUMMARY_SUCCESS,
    constraints.GET_BUG_SUMMARY_FAILURE,
);

export const {
    getBugTicketBreakdownRequest,
    getBugTicketBreakdownSuccess,
    getBugTicketBreakdownFailure,
} = createActions(
    constraints.GET_BUG_TICKET_BREAKDOWN_REQUEST,
    constraints.GET_BUG_TICKET_BREAKDOWN_SUCCESS,
    constraints.GET_BUG_TICKET_BREAKDOWN_FAILURE,
);

export const {
    resetBugSummaryRequest,
    resetBugSummarySuccess,
    resetBugSummaryFailure,
} = createActions(
    constraints.RESET_BUG_SUMMARY_REQUEST,
    constraints.RESET_BUG_SUMMARY_SUCCESS,
    constraints.RESET_BUG_SUMMARY_FAILURE,
);

export const {
    getBugsRequest,
    getBugsSuccess,
    getBugsFailure,
} = createActions(
    constraints.GET_BUGS_REQUEST,
    constraints.GET_BUGS_SUCCESS,
    constraints.GET_BUGS_FAILURE,
);

export const {
    updateComponentsRequest,
    updateComponentsSuccess,
    updateComponentsFailure,
} = createActions(
    constraints.UPDATE_COMPONENTS_REQUEST,
    constraints.UPDATE_COMPONENTS_SUCCESS,
    constraints.UPDATE_COMPONENTS_FAILURE,
);

export const {
    updateBudgetsRequest,
    updateBudgetsSuccess,
    updateBudgetsFailure,
} = createActions(
    constraints.UPDATE_BUDGETS_REQUEST,
    constraints.UPDATE_BUDGETS_SUCCESS,
    constraints.UPDATE_BUDGETS_FAILURE,
);

export const {
    updatePlanningBudgetsRequest,
    updatePlanningBudgetsSuccess,
    updatePlanningBudgetsFailure,
} = createActions(
    constraints.UPDATE_PLANNING_BUDGETS_REQUEST,
    constraints.UPDATE_PLANNING_BUDGETS_SUCCESS,
    constraints.UPDATE_PLANNING_BUDGETS_FAILURE,
);

export const {
    exportSpecDocRequest,
    exportSpecDocSuccess,
    exportSpecDocFailure,
} = createActions(
    constraints.EXPORT_SPEC_DOC_REQUEST,
    constraints.EXPORT_SPEC_DOC_SUCCESS,
    constraints.EXPORT_SPEC_DOC_FAILURE,
);

export const {
    getPullRequestsRequest,
    getPullRequestsSuccess,
    getPullRequestsFailure,
} = createActions(
    constraints.GET_PULL_REQUESTS_REQUEST,
    constraints.GET_PULL_REQUESTS_SUCCESS,
    constraints.GET_PULL_REQUESTS_FAILURE,
);

export const {
    deleteProjectRequest,
    deleteProjectSuccess,
    deleteProjectFailure,
} = createActions(
    constraints.DELETE_PROJECT_REQUEST,
    constraints.DELETE_PROJECT_SUCCESS,
    constraints.DELETE_PROJECT_FAILURE,
);
