const Permissions = {
    VIEW_OVERVIEW: 'view overview',
    VIEW_ISSUE_BREAKDOWN: 'view issue breakdown',
    VIEW_SPRINT_BREAKDOWN: 'view sprint breakdown',
    VIEW_BILLING_PERIOD_BREAKDOWN: 'view billing period breakdown',
    VIEW_SPRINT_PLAN: 'view sprint plan',
    VIEW_BUG_RATE_ANALYSIS: 'view bug rate analysis',
    VIEW_PEV_DATATABLES: 'view pev datatable',
    VIEW_SETTINGS: 'view settings',
    VIEW_PROJECT: 'view project',
    VIEW_PEV_LASTEST_SNAPSHOT: 'view pev latest snapshot',
    VIEW_TICKETS: 'view tickets',
    UPDATE_PROJECT: 'update project',
    VIEW_PEV_TAB: 'view pev tab',
    VIEW_PEV_INITIAL_VALUES: 'view pev initial values',
    DELETE_PROJECT: 'delete project',
};
export default Permissions;
