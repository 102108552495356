import React, { useRef } from 'react';
import './style.scss';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const Selector = ({ dateRanges, onSelect, onCreateReport, activePeriod }) => {
    const scrollRef = useRef(null);

    const selectPrevious = () => {
        if (activePeriod > 0) {
            onSelect({ index: activePeriod - 1, id: dateRanges[activePeriod - 1].id });
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

    return (
        <div className="date-range-container">
            {/* Left Scroll Button */}
            {dateRanges?.length > 0 && (
                <Button className="scroll-btn left" onClick={selectPrevious}>
                    <LeftOutlined />
                </Button>
            )}

            {/* Scrollable List */}
            <div className="date-range-list" ref={scrollRef}>
                {dateRanges?.length > 0 && dateRanges.map((range, index) => (
                    <Button
                        key={range.id}
                        className={`date-range-item ${index === activePeriod ? 'active' : ''}`}
                        onClick={() => {
                            onSelect({ index, id: range.id });
                        }}>
                        <p>{formatDate(range.start_date)} - {formatDate(range.end_date)}</p>
                    </Button>
                ))}
            </div>

            {/* Right Scroll Button */}
            <Button className="scroll-btn right" onClick={onCreateReport}>
                +
            </Button>
        </div>
    );
};

Selector.propTypes = {
    dateRanges: PropTypes.array,
    onSelect: PropTypes.func,
    onCreateReport: PropTypes.func,
    activePeriod: PropTypes.number,
};

Selector.defaultProps = {
    dateRanges: [],
    onSelect: () => {},
    onCreateReport: () => {},
    activePeriod: 0,
};

export default Selector;
