import { Record } from 'immutable';

const {
    FETCH_PSR_SNAPSHOT_REQUEST,
    FETCH_PSR_SNAPSHOT_SUCCESS,
    FETCH_PSR_SNAPSHOT_FAILURE,

    FETCH_ALL_SNAPSHOTS_REQUEST,
    FETCH_ALL_SNAPSHOTS_SUCCESS,
    FETCH_ALL_SNAPSHOTS_FAILURE,

    UPDATE_SNAPSHOT_REQUEST,
    UPDATE_SNAPSHOT_SUCCESS,
    UPDATE_SNAPSHOT_FAILURE,

    CREATE_PSR_SNAPSHOT_REQUEST,
    CREATE_PSR_SNAPSHOT_SUCCESS,
    CREATE_PSR_SNAPSHOT_FAILURE,
} = require('./psrActions').constraints;

const InitialState = Record({
    projectData: null,
    snapshot: null,
    dateRanges: [],
    projectName: null,
    isFetching: false,
    isUpdating: false,
    error: null,
});

export const initialState = new InitialState();

export default function psrReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case UPDATE_SNAPSHOT_REQUEST:
        return state
            .set('isUpdating', true)
            .set('error', null);

    case FETCH_PSR_SNAPSHOT_REQUEST:
    case FETCH_ALL_SNAPSHOTS_REQUEST:
    case CREATE_PSR_SNAPSHOT_REQUEST:
        return state
            .set('isFetching', true)
            .set('error', null);

    case FETCH_PSR_SNAPSHOT_SUCCESS: {
        const { snapshot } = payload;
        return state
            .set('snapshot', snapshot)
            .set('isFetching', false)
            .set('error', null);
    }

    case FETCH_PSR_SNAPSHOT_FAILURE:
    case FETCH_ALL_SNAPSHOTS_FAILURE:
    case UPDATE_SNAPSHOT_FAILURE:
    case CREATE_PSR_SNAPSHOT_FAILURE:
        return state
            .set('isFetching', false)
            .set('isUpdating', false)
            .set('error', payload);

    case FETCH_ALL_SNAPSHOTS_SUCCESS:
    case CREATE_PSR_SNAPSHOT_SUCCESS:
        return state
            .set('dateRanges', payload.dateRanges)
            .set('projectName', payload.projectName)
            .set('isFetching', false)
            .set('error', null);

    case UPDATE_SNAPSHOT_SUCCESS:
        return state
            .set('snapshot', payload.snapshot)
            .set('isUpdating', false)
            .set('error', null);

    default:
        return state;
    }
}
