import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Alert, Button, Row, Spin } from 'antd';
import moment from 'moment';
import { isEmpty } from 'underscore';
import { VerticalAlignTopOutlined, FilePdfOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { fetchPsrSnapshotRequest, createPsrSnapshotRequest, updateSnapshotRequest, fetchAllSnapshotsRequest } from '../../core/psr/psrActions';
import './psrReport.scss';
import Selector from '../../components/Selector';
import ReportCard from '../../components/ReportCard';
import ReportSummaryBox from '../../components/ReportSummaryBox';
import ReportCreator from '../../components/Modals/ReportCreator';
import EditableTable from '../../components/EditableTable';
import CustomerSatisfaction from '../../components/CustomerSatisfaction';
import { NoReportIcon } from '../../components/CustomSVGIcon';
import { CellDataTypes } from '../../components/EditableTable/CellDataType';

const PsrReport = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const projectId = location?.pathname?.split('/')[2];
    const { snapshot, error, isFetching, dateRanges, projectName, isUpdating } = useSelector(state => state.psr);
    const [selectedSnapshotId, setSelectedSnapshotId] = useState(null);
    const [activePeriod, setActivePeriod] = useState(0);
    const [reportCreatorVisible, setReportCreatorVisible] = useState(false);
    const reportContentRef = useRef(null);

    const [milestones, setMilestones] = useState(snapshot?.milestones);
    const [decisionLog, setDecisionLog] = useState(snapshot?.decision_log);
    const [projectRisks, setProjectRisks] = useState(snapshot?.project_risks);
    const [projectActions, setProjectActions] = useState(snapshot?.project_actions);

    useEffect(() => {
        if (dateRanges && dateRanges.length > 0) {
            setSelectedSnapshotId(dateRanges[dateRanges.length - 1].id);
        }
        setActivePeriod(dateRanges.length - 1);
    }, [dateRanges]);

    useEffect(() => {
        dispatch(fetchAllSnapshotsRequest({ projectId }));
    }, []);

    useEffect(() => {
        if (selectedSnapshotId) {
            dispatch(fetchPsrSnapshotRequest({ projectId, snapshotId: selectedSnapshotId }));
        }
    }, [projectId, selectedSnapshotId]);

    useEffect(() => {
        if (snapshot?.milestones) {
            setMilestones(snapshot.milestones);
        }
        if (snapshot?.decision_log) {
            setDecisionLog(snapshot.decision_log);
        }
        if (snapshot?.project_risks) {
            setProjectRisks(snapshot.project_risks);
        }
        if (snapshot?.project_actions) {
            setProjectActions(snapshot.project_actions);
        }
    }, [snapshot]);

    const handleSnapshotChange = (value) => {
        const { index, id } = value;
        setSelectedSnapshotId(id);
        setActivePeriod(index);
    };

    const handleCreateReport = (data) => {
        setReportCreatorVisible(false);

        let ragRating = data.overall_rag_rating;
        if (data.overall_rag_rating === 'GREEN') {
            ragRating = 'G';
        } else if (data.overall_rag_rating === 'AMBER') {
            ragRating = 'A';
        } else if (data.overall_rag_rating === 'RED') {
            ragRating = 'R';
        }

        const updatedData = {
            ...data,
            overall_rag_rating: ragRating,
        };

        dispatch(createPsrSnapshotRequest({
            projectId,
            data: updatedData,
        }));
    };

    const getRatingColor = (value) => {
        if (value === 'G') return '#4BCE97';
        if (value === 'A') return '#FFC400';
        if (value === 'R') return '#FF8F73';
        return '';
    };

    const getProjectPercentCompleteColor = (value) => {
        if (value >= 80) return '#4BCE97';
        if (value >= 50) return '#FFC400';
        return '#FF8F73';
    };

    // Format date for display
    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        return moment(dateString).format('DD/MM/YYYY');
    };

    const handlePrintPDF = useReactToPrint({
        content: () => reportContentRef.current,
        documentTitle: `PSR_Report_${projectName || snapshot?.project_name}_${formatDate(dateRanges[activePeriod]?.start_date)}_${formatDate(dateRanges[activePeriod]?.end_date)}`,
        pageStyle: `
            @page {
                size: A4;
                margin: 20mm;
            }
            @media print {
                body {
                    -webkit-print-color-adjust: exact;
                    print-color-adjust: exact;
                }
                .psr-report-table table {
                    width: 100%;
                    max-width: 100%;
                    font-size: 11px;
                }
                .ant-table-content {
                    overflow-x: hidden !important;
                }
                .ant-table-cell {
                    padding: 8px 4px !important;
                    white-space: normal !important;
                    width: auto !important;
                }
                .ant-table-thead th, .ant-table-tbody td {
                    width: auto !important;
                }
                table {
                    table-layout: auto !important;
                }
            }
        `,
    });

    const milestoneColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.localeCompare(b.title),
            width: 350,
        },
        {
            title: 'Amount Due',
            dataIndex: 'amount_due',
            key: 'amount_due',
            render: (text) => (text ? `£${text.toLocaleString()} + VAT` : 'N/A'),
            sorter: (a, b) => a.amount_due - b.amount_due,
            cellDataType: CellDataTypes.NUMBER,
            width: 100,
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            render: (date) => {
                if (!date || !date.length) return null;
                return date.map((d, index) => {
                    const formattedDate = formatDate(d);
                    if (index < date.length - 1) {
                        return <div key={d}>({formattedDate})</div>;
                    }
                    return <div key={d}>{formattedDate}</div>;
                });
            },
            width: 100,
        },
        {
            title: '% Complete',
            dataIndex: 'percent_complete',
            key: 'percent_complete',
            render: (text) => `${text}%`,
            sorter: (a, b) => a.percent_complete - b.percent_complete,
            cellDataType: CellDataTypes.NUMBER,
            width: 100,
        },
        {
            title: 'Status',
            dataIndex: 'status_rating',
            key: 'status_rating',
            render: (text) => (
                <div
                    style={{
                        backgroundColor: getRatingColor(text),
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                    }}>
                    {text}
                </div>
            ),
            width: 100,
            editable: true,
            cellDataType: CellDataTypes.SELECT,
            options: [
                { label: 'GREEN', value: 'G' },
                { label: 'AMBER', value: 'A' },
                { label: 'RED', value: 'R' },
            ],
        },
        {
            title: 'Invoiced Date',
            dataIndex: 'invoiced_date',
            key: 'invoiced_date',
            render: (text) => formatDate(text),
            sorter: (a, b) => new Date(a.invoiced_date || 0) - new Date(b.invoiced_date || 0),
            width: 100,
            cellDataType: CellDataTypes.DATE_PICKER,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a.status.localeCompare(b.status),
            width: 100,
        },
    ];

    const decisionLogColumns = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            width: 100,
        },
        {
            title: 'Decision',
            dataIndex: 'decision',
            key: 'decision',
            width: 150,
        },
        {
            title: 'Approved By',
            dataIndex: 'approved_by',
            key: 'approved_by',
            width: 100,
        },
        {
            title: 'Date Approved',
            dataIndex: 'date_approved',
            key: 'date_approved',
            width: 100,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            editable: true,
            key: 'notes',
            width: 150,
        },
    ];

    const projectRisksColumns = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            width: 100,
        },
        {
            title: 'Summary',
            dataIndex: 'summary',
            key: 'summary',
            width: 150,
        },
        {
            title: 'Likelihood',
            dataIndex: 'likelihood',
            key: 'likelihood',
            width: 100,
        },
        {
            title: 'Impact',
            dataIndex: 'impact',
            key: 'impact',
            width: 100,
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
            width: 100,
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            editable: true,
            width: 150,
        },

    ];

    const projectActionsColumns = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            width: 100,
        },
        {
            title: 'Summary',
            dataIndex: 'summary',
            key: 'summary',
            width: 150,
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            width: 100,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
        },
        {
            title: 'Updated',
            dataIndex: 'updated',
            key: 'updated',
            render: (text) => formatDate(text),
            width: 100,
        },
        {
            title: 'Action Description',
            dataIndex: 'action_description',
            key: 'action_description',
            width: 150,
            editable: true,
        },
    ];

    const handleMilestonesUpdate = () => {
        if (milestones && milestones.length > 0) {
            const updatedMilestones = milestones.map(milestone => {
                const updatedMilestone = { ...milestone };
                return updatedMilestone;
            });
            dispatch(updateSnapshotRequest({ projectId, snapshotId: selectedSnapshotId, data: { milestones: updatedMilestones } }));
        }
    };

    const milestoneTableHeader = () => (
        <Row style={{ justifyContent: 'space-between', marginInline: 15 }} align="middle">
            <h3>Milestones</h3>
            <div>
                <Button
                    disabled={isFetching}
                    type="primary"
                    size="medium"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    icon={<VerticalAlignTopOutlined />}
                    onClick={handleMilestonesUpdate}>
                    Update Milestones Data
                    {isFetching && <Spin style={{ marginLeft: 10 }} size="small" />}
                </Button>
            </div>
        </Row>
    );

    const renderMilestoneTable = () => {
        if (!snapshot?.milestones || snapshot.milestones.length === 0) {
            return <Alert message="No milestone data available" type="info" />;
        }

        return (
            <EditableTable
                expanded
                title={milestoneTableHeader}
                dataSource={milestones}
                columns={milestoneColumns}
                loading={isUpdating}
                setDataSource={(data) => setMilestones(data)}
                className="milestone-table"
                noFilter />
        );
    };

    const handleDecisionLogUpdate = () => {
        if (decisionLog && decisionLog.length > 0) {
            const updatedDecisionLog = decisionLog.map(decision => {
                const updatedDecision = { ...decision };
                return updatedDecision;
            });
            dispatch(updateSnapshotRequest({ projectId, snapshotId: selectedSnapshotId, data: { decision_log: updatedDecisionLog } }));
        }
    };

    const decisionLogTableHeader = () => (
        <Row style={{ justifyContent: 'space-between', marginInline: 15 }} align="middle">
            <h3>Decision Log</h3>
            <div>
                <Button
                    disabled={isFetching}
                    type="primary"
                    size="medium"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    icon={<VerticalAlignTopOutlined />}
                    onClick={handleDecisionLogUpdate}>
                    Update Decision Log Data
                    {isFetching && <Spin style={{ marginLeft: 10 }} size="small" />}
                </Button>
            </div>
        </Row>
    );

    const renderDecisionLogTable = () => {
        if (!decisionLog || decisionLog.length === 0) {
            return <Alert message="No decision log data available" type="info" />;
        }

        return (
            <EditableTable
                expanded
                title={decisionLogTableHeader}
                dataSource={decisionLog}
                columns={decisionLogColumns}
                setDataSource={(data) => setDecisionLog(data)}
                noFilter
                isFetching={isFetching}
                loading={isUpdating} />
        );
    };

    const handleProjectRisksUpdate = () => {
        if (projectRisks && projectRisks.length > 0) {
            const updatedProjectRisks = projectRisks.map(risk => {
                const updatedRisk = { ...risk };
                return updatedRisk;
            });
            dispatch(updateSnapshotRequest({ projectId, snapshotId: selectedSnapshotId, data: { project_risks: updatedProjectRisks } }));
        }
    };

    const projectRisksTableHeader = () => (
        <Row style={{ justifyContent: 'space-between', marginInline: 15 }} align="middle">
            <h3>Project Risks</h3>
            <div>
                <Button
                    disabled={isFetching}
                    type="primary"
                    size="medium"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    icon={<VerticalAlignTopOutlined />}
                    onClick={handleProjectRisksUpdate}>
                    Update Project Risks Data
                    {isFetching && <Spin style={{ marginLeft: 10 }} size="small" />}
                </Button>
            </div>
        </Row>
    );

    const renderProjectRisksTable = () => {
        if (!projectRisks || projectRisks.length === 0) {
            return <Alert message="No project risks data available" type="info" />;
        }

        return (
            <EditableTable
                expanded
                title={projectRisksTableHeader}
                dataSource={projectRisks}
                columns={projectRisksColumns}
                setDataSource={(data) => setProjectRisks(data)}
                noFilter
                isFetching={isFetching}
                loading={isUpdating} />
        );
    };

    const handleProjectActionsUpdate = () => {
        if (projectActions && projectActions.length > 0) {
            const updatedProjectActions = projectActions.map(action => {
                const updatedAction = { ...action };
                if (action.updated) {
                    updatedAction.updated = moment(action.updated, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
                }
                return updatedAction;
            });
            dispatch(updateSnapshotRequest({ projectId, snapshotId: selectedSnapshotId, data: { project_actions: updatedProjectActions } }));
        }
    };

    const projectActionsTableHeader = () => (
        <Row style={{ justifyContent: 'space-between', marginInline: 15 }} align="middle">
            <h3>Project Actions</h3>
            <div>
                <Button
                    disabled={isFetching}
                    type="primary"
                    size="medium"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    icon={<VerticalAlignTopOutlined />}
                    onClick={handleProjectActionsUpdate}>
                    Update Project Actions Data
                    {isFetching && <Spin style={{ marginLeft: 10 }} size="small" />}
                </Button>
            </div>
        </Row>
    );

    const renderProjectActionsTable = () => {
        if (!projectActions || projectActions.length === 0) {
            return <Alert message="No project actions data available" type="info" />;
        }

        return (
            <EditableTable
                expanded
                title={projectActionsTableHeader}
                dataSource={projectActions}
                setDataSource={(data) => setProjectActions(data)}
                columns={projectActionsColumns}
                noFilter
                isFetching={isFetching}
                loading={isUpdating} />
        );
    };

    return (
        <div className="psr-report-container">
            {isFetching && (
                <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                    <Spin size="large" />
                </div>
            )}
            {!isFetching && (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 100 }}>
                        <h2 style={{ textAlign: 'center' }}>{projectName || snapshot?.project_name}</h2>
                    </div>

                    {!isEmpty(dateRanges) ? (
                        <div className="psr-report-content">
                            <div style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
                                <Selector dateRanges={dateRanges} onSelect={handleSnapshotChange} onCreateReport={setReportCreatorVisible} activePeriod={activePeriod} />
                                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 20, paddingTop: 10 }}>
                                    <Button
                                        type="primary-outline"
                                        className="primary-button"
                                        icon={<FilePdfOutlined />}
                                        onClick={handlePrintPDF}
                                        disabled={isFetching}>
                                        Export to PDF
                                    </Button>
                                </div>
                            </div>

                            <div ref={reportContentRef} className="printable-report">
                                <h3 style={{ textAlign: 'center' }}>PSR Report</h3>
                                <h4 style={{ textAlign: 'center' }}>Report for {formatDate(dateRanges[activePeriod]?.start_date)} - {formatDate(dateRanges[activePeriod]?.end_date)}</h4>
                                {!isEmpty(dateRanges) && (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                        <ReportCard title="Overall RAG Rating" value={snapshot?.overall_rag_rating} color={getRatingColor(snapshot?.overall_rag_rating)} />
                                        <ReportCard title="Project Percent Complete" value={`${snapshot?.project_percent_complete} %`} color={getProjectPercentCompleteColor(snapshot?.project_percent_complete)} />
                                        <ReportCard title="Customer Satisfaction Score" value={snapshot?.customer_satisfaction_score} color="#4BCE97" />
                                    </div>
                                )}
                                <ReportSummaryBox projectType={snapshot?.project_type} snapshotData={snapshot} dateRange={`${formatDate(dateRanges[activePeriod]?.start_date)} - ${formatDate(dateRanges[activePeriod]?.end_date)}`} />
                                <div className="psr-report-table milestone-table-container">
                                    {!isFetching && renderMilestoneTable()}
                                </div>
                                <div className="psr-report-table">
                                    {!isFetching && renderDecisionLogTable()}
                                </div>
                                <div className="psr-report-table">
                                    {!isFetching && renderProjectRisksTable()}
                                </div>
                                <div className="psr-report-table">
                                    {!isFetching && renderProjectActionsTable()}
                                </div>

                                {error && <Alert message="Error loading PSR data" description={error} type="error" showIcon />}

                                <CustomerSatisfaction data={snapshot} />
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <NoReportIcon />
                            <h3>No PSR Available</h3>
                            <div>
                                <Button style={{ width: 200 }} type="primary" onClick={() => setReportCreatorVisible(true)}>+ Create Report</Button>
                            </div>
                        </div>
                    )}
                </>
            )}
            <ReportCreator visible={reportCreatorVisible} onClose={() => setReportCreatorVisible(false)} onSubmit={handleCreateReport} />
        </div>
    );
};

export default PsrReport;
