import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';

import Routes from './core/routes';
import debug from './core/utils/debug';

import './styles/style.css';
/** Actions: The necessary actions for dispatching our bootstrap values */
import { setVersion } from './core/global/globalActions';

/** The version of the app but not displayed yet */
import pack from '../package.json';

/** configureStore */
import configureStore from './core/store';
import { ProjectProvider } from './components/ProjectProvider';

export { default as Calendar } from './components/PickerComponent/Calender';
export { default as DatePicker } from './components/PickerComponent/DatePicker';
// Get bugsnag error wrapper
const ErrorWrapper = debug.getWrapper();

const globalStore = configureStore({});
const { store, persistor } = globalStore;

const VERSION = pack.version;

store.dispatch(setVersion(VERSION));
// Define theme based on your existing color variables
const theme = {
    token: {
        defaultHoverBorderColor: '#860000',
        primaryColor: '#860000',
    },
    components: {
        Input: {
            activeBorderColor: '#860000',
            hoverBorderColor: '#860000',
            primaryColor: '#860000',
        },
        InputNumber: {
            activeBorderColor: '#860000',
            hoverBorderColor: '#860000',
            primaryColor: '#860000',
        },
        Select: {
            activeBorderColor: '#860000',
            hoverBorderColor: '#860000',
            primaryColor: '#860000',
        },
        DatePicker: {
            activeBorderColor: '#860000',
            hoverBorderColor: '#860000',
            primaryColor: '#860000',
        },
        Switch: {
            handleBg: '#ffffff',
            colorPrimary: '#860000',
            colorPrimaryHover: '#860000',
            colorPrimaryBorder: '#860000',

        },
        Checkbox: {
            colorPrimary: '#860000',
            colorPrimaryHover: '#860000',
            colorPrimaryBorder: '#860000',

        },
        // Default button styles
        Button: {
            colorPrimary: '#860000',
            colorPrimaryHover: '#860000',
            colorPrimaryActive: '#860000',
            colorPrimaryText: '#ffffff',
            colorPrimaryTextHover: '#ffffff',
            colorPrimaryTextActive: '#ffffff',
        },
        Table: {
            headerBg: '#F3ECEC',
            borderColor: '#D6D6D6',
            colorText: '#4E4848',
            headerColor: '#4E4848',
            footerColor: '#4E4848',
            // Adding alternating row colors
            colorBgContainer: '#FFFCFD', // Even rows (same as main background)
            rowSelectedBg: '#F3ECEC',
            rowSelectedHoverBg: '#F3ECEC', // Explicitly set to same as selected background
        },
        Pagination: {
            colorPrimary: '#860000',
            colorPrimaryHover: '#860000',
            colorPrimaryActive: '#860000',
        },
    },
};
ReactDOM.render(
    <ErrorWrapper>
        <Provider store={store}>
            <ProjectProvider>
                <PersistGate loading={null} persistor={persistor}>
                    <ConfigProvider theme={theme}>
                        <BrowserRouter basename="/">
                            <Routes />
                        </BrowserRouter>
                    </ConfigProvider>
                </PersistGate>
            </ProjectProvider>
        </Provider>
    </ErrorWrapper>,
    document.getElementById('root'),
);
