import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constraints = keyMirror({
    FETCH_PSR_SNAPSHOT_REQUEST: null,
    FETCH_PSR_SNAPSHOT_SUCCESS: null,
    FETCH_PSR_SNAPSHOT_FAILURE: null,

    CREATE_PSR_SNAPSHOT_REQUEST: null,
    CREATE_PSR_SNAPSHOT_SUCCESS: null,
    CREATE_PSR_SNAPSHOT_FAILURE: null,

    FETCH_ALL_SNAPSHOTS_REQUEST: null,
    FETCH_ALL_SNAPSHOTS_SUCCESS: null,
    FETCH_ALL_SNAPSHOTS_FAILURE: null,

    UPDATE_SNAPSHOT_REQUEST: null,
    UPDATE_SNAPSHOT_SUCCESS: null,
    UPDATE_SNAPSHOT_FAILURE: null,
});

export const {
    fetchPsrSnapshotRequest,
    fetchPsrSnapshotSuccess,
    fetchPsrSnapshotFailure,

    createPsrSnapshotRequest,
    createPsrSnapshotSuccess,
    createPsrSnapshotFailure,

    fetchAllSnapshotsRequest,
    fetchAllSnapshotsSuccess,
    fetchAllSnapshotsFailure,

    updateSnapshotRequest,
    updateSnapshotSuccess,
    updateSnapshotFailure,
} = createActions(
    constraints.FETCH_PSR_SNAPSHOT_REQUEST,
    constraints.FETCH_PSR_SNAPSHOT_SUCCESS,
    constraints.FETCH_PSR_SNAPSHOT_FAILURE,

    constraints.CREATE_PSR_SNAPSHOT_REQUEST,
    constraints.CREATE_PSR_SNAPSHOT_SUCCESS,
    constraints.CREATE_PSR_SNAPSHOT_FAILURE,

    constraints.FETCH_ALL_SNAPSHOTS_REQUEST,
    constraints.FETCH_ALL_SNAPSHOTS_SUCCESS,
    constraints.FETCH_ALL_SNAPSHOTS_FAILURE,

    constraints.UPDATE_SNAPSHOT_REQUEST,
    constraints.UPDATE_SNAPSHOT_SUCCESS,
    constraints.UPDATE_SNAPSHOT_FAILURE,
);
